var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "alertlist"
  }, [_c('r-section-header', {
    attrs: {
      "label": "Data alerts"
    },
    scopedSlots: _vm._u([{
      key: "end",
      fn: function () {
        return [_c('v-row', {
          staticClass: "ma-0 justify-end"
        }, [_c('r-search-input', {
          staticStyle: {
            "max-width": "300px"
          },
          attrs: {
            "value": _vm.search,
            "placeholder": "Search data"
          },
          on: {
            "change": _vm.onChangeSearch
          }
        })], 1)];
      },
      proxy: true
    }])
  }), _vm.fetchAlertsError ? _c('r-error-message', {
    attrs: {
      "errors": [_vm.fetchAlertsError]
    }
  }) : _vm.fetchingAlerts ? _c('v-row', {
    staticClass: "ma-0"
  }, _vm._l(4, function (index) {
    return _c('v-col', {
      key: `alertcards--loading--${index}`,
      staticClass: "pa-0 alertcards--cell px-2"
    }, [_c('v-skeleton-loader', {
      staticClass: "elevation-0 alertcards--loader",
      attrs: {
        "type": "list-item, list-item-avatar-three-line, card-heading",
        "height": "144"
      }
    })], 1);
  }), 1) : _c('v-row', {
    staticClass: "ma-0"
  }, [_vm.noData ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('no-listing-results')], 1) : _vm._e(), _c('v-row', {
    staticClass: "four-cols"
  }, _vm._l(_vm.alerts, function (alert, i) {
    return _c('v-col', {
      key: `alertscards--row-key-${i}`,
      attrs: {
        "cols": "3"
      }
    }, [_c('r-alert-card', {
      staticClass: "alertscards--card",
      attrs: {
        "type": alert.type,
        "date": alert.created,
        "data": alert.alertData
      }
    })], 1);
  }), 1), !_vm.noData ? _c('v-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('r-pagination', {
    attrs: {
      "page": _vm.page,
      "total-pages": Math.ceil(_vm.totalAlerts / _vm.pageSize),
      "justify": "end"
    },
    on: {
      "click:page": _vm.onClickPage
    }
  })], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }