<template>
  <div class="alertlist">
    <r-section-header label="Data alerts">
      <template v-slot:end>
        <v-row
          class="ma-0 justify-end"
        >
          <r-search-input
            :value="search"
            placeholder="Search data"
            style="max-width:300px"
            @change="onChangeSearch"
          />
        </v-row>
      </template>
    </r-section-header>
    <r-error-message
      v-if="fetchAlertsError"
      :errors="[fetchAlertsError]"
    />
    <v-row
      v-else-if="fetchingAlerts"
      class="ma-0"
    >
      <v-col
        v-for="index in 4"
        :key="`alertcards--loading--${index}`"
        class="pa-0 alertcards--cell px-2"
      >
        <v-skeleton-loader
          class="elevation-0 alertcards--loader"
          type="list-item, list-item-avatar-three-line, card-heading"
          height="144"
        />
      </v-col>
    </v-row>
    <v-row
      v-else
      class="ma-0"
    >
      <v-col
        v-if="noData"
        cols="12"
      >
        <no-listing-results />
      </v-col>

      <v-row class="four-cols">
        <v-col
          v-for="(alert, i) in alerts"
          :key="`alertscards--row-key-${i}`"
          cols="3"
        >
          <r-alert-card
            class="alertscards--card"
            :type="alert.type"
            :date="alert.created"
            :data="alert.alertData"
          />
        </v-col>
      </v-row>

      <v-col
        v-if="!noData"
        cols="12"
        class="mt-3"
      >
        <r-pagination
          :page="page"
          :total-pages="Math.ceil(totalAlerts / pageSize)"
          justify="end"
          @click:page="onClickPage"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import RSearchInput from '@/components/library/molecules/RSearchInput'
import RSectionHeader from '@/components/library/molecules/RSectionHeader'
import RAlertCard from '@/components/library/molecules/RAlertCard'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import RPagination from '@/components/library/molecules/RPagination'
import NoListingResults from '@/components/app/data/NoListingResults'
import debounce from '@/utils/debounce'

const ITEMS_PER_ROW = 4

export default {
  name: 'AlertList',
  components: {
    NoListingResults,
    RAlertCard,
    RPagination,
    RSearchInput,
    RErrorMessage,
    RSectionHeader,
  },
  data: () => ({
    ITEMS_PER_ROW,
    debouncedFetchData: null,
    search: '',
  }),
  computed: {
    ...mapState('alerts', [
      'fetchingAlerts',
      'fetchAlertsError',
      'alerts',
      'totalAlerts',
      'page',
      'pageSize',
    ]),
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
    noData() {
      const { alerts, fetchingAlerts } = this
      return !fetchingAlerts && !alerts?.length
    },
  },
  watch: {
    selectedProjectId() {
      this.setPage({ page: 1 })
      this.fetchData()
    },
  },
  mounted() {
    this.$data.debouncedFetchData = debounce(() => this.fetchData(), 100)
    this.fetchData()
  },
  methods: {
    ...mapActions('alerts', [
      'fetchAlerts',
      'setPage',
    ]),
    onChangeSearch({ value: search }) {
      this.search = search
      this.setPage({ page: 1 })
      this.$data.debouncedFetchData()
    },
    onClickPage({ value: page }) {
      this.setPage({ page })
      this.$data.debouncedFetchData()
    },
    fetchData() {
      const payload = {
        page: this.page,
        pageSize: this.pageSize,
        search: this.search,
        projectId: this.selectedProjectId,
      }

      this.fetchAlerts(payload)
    },
  },
}
</script>
